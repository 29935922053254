<template>
    <div>
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                class="header"
                title="目录"
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
            <template #right>
                <div @click="TextClick">
                    <img src="../../assets/images/icon3.png" alt="" class="icon3-img" v-if="text_show">
                    <img src="../../assets/images/icon4.png" alt="" class="icon3-img" v-else>
                </div>
            </template>
        </van-nav-bar>
        <div v-if="chapterList.chapters">
            <div class="directory directory-style" v-if="end">
                <p class="directory-title">{{chapterList.iswz == 1 ?'连载中':'已完结'}}
                    <span>共{{chapterList.allchapter}}章</span>
                </p>
                <p class="directory-subtitle" @click="ReadingClick(end)">{{end.title}}</p>
            </div>
            <template v-for="item in chapterList.chapters">
                <div class="directory" @click="ReadingClick(item)">
                    <p class="directory-text">{{item.title}}</p>
                    <template v-if="user_infoList.is_vip !='1'">
                        <div v-if="item.is_pay == '0' && item.coin != '0'">
                            <p class="free">
                                <span class="coin-text">{{item.coin}}</span>
                                <img src="../../assets/images/bookbean_icon.png" alt="" class="bookbean_icon"/>
                            </p>
                        </div>
                        <div v-else>
                            <p class="free">免费</p>
                        </div>
                    </template>
                    <template v-else>
                        <p class="vip">
                            <img src="../../assets/images/vip_tit_img.png" alt="" class="vip_tit_img">
                        </p>
                    </template>
                </div>
            </template>
            <div class="no" v-if="chapterList.chapters.length == 0">
                <No title="暂无章节"></No>
            </div>
        </div>
    </div>
</template>

<script>
    import No from "../../components/No";
    export default {
        name: "Directory",
        components: {
            No,
        },
        data() {
            return {
                id: this.$route.query.id,
                chapterList: [],
                end: {},
                text_show: false,
                user_infoList: [],
            }
        },
        created() {
            this.chapterPOST()
            this.user_info()
        },
        methods: {
            user_info() {
                this.$axios.get('/api/user_info')
                    .then(res => {
                        var res = res.data.data
                        this.user_infoList = res
                    })
            },
            TextClick() {
                if (!this.chapterList.chapters) return

                this.text_show = !this.text_show
                this.chapterList.chapters.reverse()
            },
            chapterPOST() {
                this.$axios.post('/api/bookstores/chapter', {
                    anime_id: this.id
                })
                    .then(res => {
                        this.chapterList = res.data.data
                        this.end = this.chapterList.chapters[this.chapterList.chapters.length - 1]
                    })
                    .catch(err => {
                        this.$toast(err.response.data.message);
                    })
            },
            ReadingClick(item) {
                this.$router.push({
                    path: '/reading?id=' + this.id + '&chaps=' + item.chaps
                })
            },
        },
    }
</script>

<style scoped>
    .vip {
        background: #000;
        padding: 0 5px;
        border-radius: 5px;
    }

    .vip_tit_img {
        width: 45px;
        height: 20px;
    }

    .free {
        color: #fff;
        font-size: 20px;
        background: #4d77fd7d;
        padding: 5px 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .coin-text {
        font-size: 20px;
        color: #fff;
    }

    .bookbean_icon {
        width: 15px;
        height: 15px;
        display: inline-block;
        margin: 0 0 0 5px;
    }

    .directory-style {
        position: sticky;
        top: 86px;
        background: #fff;
    }

    .directory-text {
        width: 75%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #303133;
        font-size: 30px;
    }

    .directory-subtitle {
        color: #303133;
        font-size: 26px;
        width: 48%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: right;
    }

    .suo-img {
        width: 28px;
        height: 30px;
    }

    .directory-title {
        color: #303133;
        font-size: 30px;
        width: 48%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .directory-title > span {
        color: #BFC2CC;
        font-size: 20px;
    }

    .directory {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 35px 40px;
        border-bottom: 1px solid #F2F5F8;
    }

    .icon3-img {
        width: 30px;
        height: 30px;
    }
</style>